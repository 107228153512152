export const environmentForWeb = {
  production: true,
  env: 'test',
  host: 'https://test.danceflavors.com/app',
  apiAppointmentUrl: '/api-appointment',
  apiDanceClassUrl: '/api-dance-class',
  apiDanceSchoolUrl: '/api-dance-school',
  apiAdministrationUrl: '/api-administration',
  apiNotificationsUrl: '/api-notification',
  apiRoomRentalsUrl: '/api-room-rental',
  apiOrdersUrl: '/api-order',
  apiPaymentsUrl: '/api-payment',
  apiWebsocketsUrl: '/api-websocket',
  apiMessageUrl: '/api-message',
  apiChatUrl: '/api-chat',
  apiChatUrlWS: 'wss://www.test.danceflavors.com/chat-websocket',
  apiUsersUrl: '/api-user',
  apiAuthUrl: '/api-auth',
  apiDanceEventsUrl: '/api-dance-event',
  apiCoursesUrl: '/api-course',
  apiTicketUrl: '/api-ticket',
  apiFollowUrl: '/api-follow',
  redisHost: 'websocket-redis',
  mobileAppLinks: {
    android:
      'https://play.google.com/store/apps/details?id=com.danceflavors_app',
    ios: 'https://apps.apple.com/de/app/danceflavors/id1583746248',
  },
  googleMapsApiKey: 'AIzaSyAfCcAj4BeosShhh6bNzdhL2axDXKsJkYQ',
  stripePublishableKey:
    'pk_test_51LfLOkD1fTq5Z6Hu5P30npjNtxRjygglO4fLz7gqJKeARQWWPrvRIlOGbMzx782MwylM1dAb77WcRqVn69I1JcXU00ZLLBLm7u',
  stripePricingTableIdGerman: 'prctbl_1MCPzmD1fTq5Z6HuBMwpzV7Q',
  stripePricingTableIdEnglish: 'prctbl_1MQng8D1fTq5Z6Huxz2CSK1m',
  multiChatEnabled: false,
  paypalClientId:
    'ARZgHdTWENE049G1CA1Vmmsor6r8SA0K8aYBp1IGt7ekKL8M4uow0gh5_O04X5mbYQE7kMbvpYPLb6S-',
  enableStripeSubscription: true,
  googleAuthClientId:
    '694808709391-m217l8tvpgqm2g3rn8sn924asoh5oh7j.apps.googleusercontent.com',
  apiKey: 'ABCDEFG123434oisojdfoiasdjfoiasjo',
  apiOmniWs: 'wss://www.test.danceflavors.com',
  dmPassSubscriptionFeature: true,
  participateClasses: true
};

export const environmentForAdmin = {
  production: true,
  apiUrl: '/api',
  apiNotificationsUrl: '/api-notification',
  apiRoomRentalsUrl: '/api-room-rental',
  apiWebsocketsUrl: '/api-websocket',
  apiOrdersUrl: '/api-order',
};
